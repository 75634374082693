import Decimal from "decimal.js";
import * as React from "react";
import { useProjectRecordQuery } from "../../../clay/api";
import { Dictionary, Money } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi, useQuickAllRecords } from "../../../clay/quick-cache";
import { OptionalFormField } from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import {
    calcContingencyItemTotal,
    isLumpSumUnitType,
} from "../../contingency/table";
import { UNIT_TYPE_META } from "../../estimate/types/table";
import { RichTextWidget } from "../../rich-text-widget";
import { ExportDataTable } from "../accounting/ExportDataTable";
import { DETAIL_SHEET_META } from "../detail-sheet/table";
import { Project, PROJECT_META } from "../table";

export type Data = Project;

export const Fields = {
    iciNotes: OptionalFormField(RichTextWidget),
};

function Component(props: Props) {
    const detailSheets = useProjectRecordQuery(
        DETAIL_SHEET_META,
        props.data.id.uuid
    );

    const unitTypes = useQuickAllRecords(UNIT_TYPE_META);

    type Row = {
        groupCode: string;
        groupDescription: string;
        billingItem: string;
        description: string;
        unitOfMeasure: string;
        billingUnitPrice: null | Money;
        originalQuantity: null | Decimal;
        originalAmount: Decimal;
    };

    const rows = React.useMemo(() => {
        if (!unitTypes || !detailSheets) {
            return undefined;
        }

        const rows: Row[] = [];

        let nextFixedPriceItem = 1;
        let nextUnitRateItem = 1;
        let nextTimeAndMaterialsItem = 1;

        for (const detailSheet of detailSheets) {
            for (const schedule of detailSheet.schedules) {
                const generatedBillingItem = `10${nextFixedPriceItem++}0`;
                rows.push({
                    groupCode: schedule.groupCode || "10",
                    groupDescription: "Fixed Price",
                    billingItem: schedule.billingItem || generatedBillingItem,
                    description: schedule.description,
                    unitOfMeasure: "LS",
                    billingUnitPrice: null,
                    originalQuantity: null,
                    originalAmount: schedule.price,
                });
            }
            for (const contingencyItem of detailSheet.contingencyItems) {
                const groupCode =
                    contingencyItem.groupCode ||
                    (isLumpSumUnitType(contingencyItem.type) ? "30" : "20");
                const generatedBillingItem = `${groupCode}${
                    isLumpSumUnitType(contingencyItem.type)
                        ? nextTimeAndMaterialsItem++
                        : nextUnitRateItem++
                }0`;
                rows.push({
                    groupCode,
                    groupDescription: isLumpSumUnitType(contingencyItem.type)
                        ? "Time & Materials"
                        : "Fixed Price",
                    billingItem:
                        contingencyItem.billingItem || generatedBillingItem,
                    description: contingencyItem.description,
                    unitOfMeasure:
                        unitTypes.find(
                            (item) => item.id.uuid === contingencyItem.type
                        )?.spectrumCode || "???",
                    billingUnitPrice: isLumpSumUnitType(contingencyItem.type)
                        ? null
                        : contingencyItem.rate,
                    originalQuantity: isLumpSumUnitType(contingencyItem.type)
                        ? null
                        : contingencyItem.quantity,
                    originalAmount: calcContingencyItemTotal(contingencyItem),
                });
            }
        }
        return rows;
    }, [detailSheets, unitTypes]);

    const columns = React.useMemo(() => {
        return {
            Company_Code() {
                return "";
            },
            Customer_Code() {
                return "";
            },
            Job_Number() {
                return "10" + props.data.projectNumber?.toString() || "";
            },
            Group_Code(item) {
                return item.groupCode;
            },
            Group_Description(item) {
                return item.groupDescription;
            },
            Billing_Item(item) {
                return item.billingItem;
            },
            Billing_Description(item) {
                return item.description.substring(0, 30);
            },
            Additional_BillingDescription(item) {
                return item.description.substring(30, 70);
            },
            GL_Account(item) {
                return "";
            },
            Taxable() {
                return "";
            },
            Unit_Of_Measure(item) {
                return item.unitOfMeasure;
            },
            Billing_Unit_Price(item) {
                return item.billingUnitPrice;
            },
            Original_Quantity(item) {
                return item.originalQuantity;
            },
            Original_Amount(item) {
                return item.originalAmount;
            },
        } satisfies Record<string, (item: Row) => string | Decimal | null>;
    }, [props.data.projectNumber]);
    return (
        <>
            <ExportDataTable
                rows={rows}
                columns={columns}
                money={new Set(["Original_Est_Cost"])}
                name={`Spectrum Export ${
                    props.data.projectNumber
                } - ${new Date().toString()}.xlsx`}
            />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.iciNotes>;
type ExtraProps = {};
type BaseState = {
    iciNotes: WidgetState<typeof Fields.iciNotes>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "ICI_NOTES"; action: WidgetAction<typeof Fields.iciNotes> };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(Fields.iciNotes, data.iciNotes, cache, "iciNotes", errors);
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "ICI_NOTES": {
            const inner = Fields.iciNotes.reduce(
                state.iciNotes,
                data.iciNotes,
                action.action,
                subcontext
            );
            return {
                state: { ...state, iciNotes: inner.state },
                data: { ...data, iciNotes: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    iciNotes: function (
        props: WidgetExtraProps<typeof Fields.iciNotes> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "ICI_NOTES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "iciNotes", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.iciNotes.component
                state={context.state.iciNotes}
                data={context.data.iciNotes}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Ici Notes"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: PROJECT_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let iciNotesState;
        {
            const inner = Fields.iciNotes.initialize(
                data.iciNotes,
                subcontext,
                subparameters.iciNotes
            );
            iciNotesState = inner.state;
            data = { ...data, iciNotes: inner.data };
        }
        let state = {
            initialParameters: parameters,
            iciNotes: iciNotesState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={PROJECT_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    iciNotes: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.iciNotes>
    >;
};
// END MAGIC -- DO NOT EDIT
