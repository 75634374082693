import { Decimal } from "decimal.js";
import { Money, Quantity } from "../../clay/common";
import { Link } from "../../clay/link";
import { RecordMeta } from "../../clay/meta";
import { genUUID, UUID } from "../../clay/uuid";
import { ItemType, UnitType } from "../estimate/types/table";
import { MasterFormatCode } from "../project/master-format-codes/table";
import {
    JSONToProjectDescriptionDetail,
    ProjectDescriptionDetail,
    ProjectDescriptionDetailJSON,
    ProjectDescriptionDetailToJSON,
    PROJECT_DESCRIPTION_DETAIL_META,
    repairProjectDescriptionDetailJSON,
} from "../project/projectDescriptionDetail/table";

//!Data
export type ContingencyItem = {
    id: UUID;
    masterFormatCode: Link<MasterFormatCode>;
    itemType: Link<ItemType>;
    nonCfExpense: boolean;
    type: Link<UnitType>;
    description: string;
    quantity: Quantity;
    rate: Money;
    certifiedForemanRate: Money;
    projectDescription: ProjectDescriptionDetail;
    hours: Quantity;
    hourRate: Money;
    materials: Quantity;
    materialsRate: Money;

    groupCode: string | null;
    billingItem: string | null;
};

export const CASH_ALLOWANCE_CONTINGENCY_TYPE =
    "17af3398-c612-4c61-b2df-872decb10821";
export const CONTINGENCY_ALLOWANCE_CONTINGENCY_TYPE =
    "cf3612af-24c7-424c-b087-af08935de43f";

export function computeContingencyItemRemdalCost(item: ContingencyItem) {
    return calcContingencyItemCertifiedForemanTotal(item);
}

export function isNoProfitUnitType(type: Link<UnitType>) {
    return type == CASH_ALLOWANCE_CONTINGENCY_TYPE;
}

export function isLumpSumUnitType(type: Link<UnitType>) {
    return (
        type == CASH_ALLOWANCE_CONTINGENCY_TYPE ||
        type === CONTINGENCY_ALLOWANCE_CONTINGENCY_TYPE
    );
}

export function calcContingencyItemTotal(item: ContingencyItem): Money {
    return item.quantity.times(item.rate).toDecimalPlaces(2);
}

export function calcContingencyItemCertifiedForemanTotal(
    item: ContingencyItem
): Money {
    return item.quantity.times(item.certifiedForemanRate).toDecimalPlaces(2);
}

// BEGIN MAGIC -- DO NOT EDIT
export type ContingencyItemJSON = {
    id: string;
    masterFormatCode: string | null;
    itemType: string | null;
    nonCfExpense: boolean;
    type: string | null;
    description: string;
    quantity: string;
    rate: string;
    certifiedForemanRate: string;
    projectDescription: ProjectDescriptionDetailJSON;
    hours: string;
    hourRate: string;
    materials: string;
    materialsRate: string;
    groupCode: string | null;
    billingItem: string | null;
};

export function JSONToContingencyItem(
    json: ContingencyItemJSON
): ContingencyItem {
    return {
        id: { uuid: json.id },
        masterFormatCode: json.masterFormatCode,
        itemType: json.itemType,
        nonCfExpense: json.nonCfExpense,
        type: json.type,
        description: json.description,
        quantity: new Decimal(json.quantity),
        rate: new Decimal(json.rate),
        certifiedForemanRate: new Decimal(json.certifiedForemanRate),
        projectDescription: JSONToProjectDescriptionDetail(
            json.projectDescription
        ),
        hours: new Decimal(json.hours),
        hourRate: new Decimal(json.hourRate),
        materials: new Decimal(json.materials),
        materialsRate: new Decimal(json.materialsRate),
        groupCode: json.groupCode,
        billingItem: json.billingItem,
    };
}
export type ContingencyItemBrokenJSON = {
    id?: string;
    masterFormatCode?: string | null;
    itemType?: string | null;
    nonCfExpense?: boolean;
    type?: string | null;
    description?: string;
    quantity?: string;
    rate?: string;
    certifiedForemanRate?: string;
    projectDescription?: ProjectDescriptionDetailJSON;
    hours?: string;
    hourRate?: string;
    materials?: string;
    materialsRate?: string;
    groupCode?: string | null;
    billingItem?: string | null;
};

export function newContingencyItem(): ContingencyItem {
    return JSONToContingencyItem(repairContingencyItemJSON(undefined));
}
export function repairContingencyItemJSON(
    json: ContingencyItemBrokenJSON | undefined
): ContingencyItemJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            masterFormatCode: json.masterFormatCode || null,
            itemType: json.itemType || null,
            nonCfExpense: json.nonCfExpense || false,
            type: json.type || null,
            description: json.description || "",
            quantity: json.quantity || "0",
            rate: json.rate || "0",
            certifiedForemanRate: json.certifiedForemanRate || "0",
            projectDescription: repairProjectDescriptionDetailJSON(
                json.projectDescription
            ),
            hours: json.hours || "0",
            hourRate: json.hourRate || "0",
            materials: json.materials || "0",
            materialsRate: json.materialsRate || "0",
            groupCode: json.groupCode ?? null,
            billingItem: json.billingItem ?? null,
        };
    } else {
        return {
            id: undefined || genUUID(),
            masterFormatCode: undefined || null,
            itemType: undefined || null,
            nonCfExpense: undefined || false,
            type: undefined || null,
            description: undefined || "",
            quantity: undefined || "0",
            rate: undefined || "0",
            certifiedForemanRate: undefined || "0",
            projectDescription: repairProjectDescriptionDetailJSON(undefined),
            hours: undefined || "0",
            hourRate: undefined || "0",
            materials: undefined || "0",
            materialsRate: undefined || "0",
            groupCode: undefined ?? null,
            billingItem: undefined ?? null,
        };
    }
}

export function ContingencyItemToJSON(
    value: ContingencyItem
): ContingencyItemJSON {
    return {
        id: value.id.uuid,
        masterFormatCode: value.masterFormatCode,
        itemType: value.itemType,
        nonCfExpense: value.nonCfExpense,
        type: value.type,
        description: value.description,
        quantity: value.quantity.toString(),
        rate: value.rate.toString(),
        certifiedForemanRate: value.certifiedForemanRate.toString(),
        projectDescription: ProjectDescriptionDetailToJSON(
            value.projectDescription
        ),
        hours: value.hours.toString(),
        hourRate: value.hourRate.toString(),
        materials: value.materials.toString(),
        materialsRate: value.materialsRate.toString(),
        groupCode: value.groupCode,
        billingItem: value.billingItem,
    };
}

export const CONTINGENCY_ITEM_META: RecordMeta<
    ContingencyItem,
    ContingencyItemJSON,
    ContingencyItemBrokenJSON
> & { name: "ContingencyItem" } = {
    name: "ContingencyItem",
    type: "record",
    repair: repairContingencyItemJSON,
    toJSON: ContingencyItemToJSON,
    fromJSON: JSONToContingencyItem,
    fields: {
        id: { type: "uuid" },
        masterFormatCode: { type: "uuid", linkTo: "MasterFormatCode" },
        itemType: { type: "uuid", linkTo: "ItemType" },
        nonCfExpense: { type: "boolean" },
        type: { type: "uuid", linkTo: "UnitType" },
        description: { type: "string" },
        quantity: { type: "quantity" },
        rate: { type: "money" },
        certifiedForemanRate: { type: "money" },
        projectDescription: PROJECT_DESCRIPTION_DETAIL_META,
        hours: { type: "quantity" },
        hourRate: { type: "money" },
        materials: { type: "quantity" },
        materialsRate: { type: "money" },
        groupCode: { type: "string?" },
        billingItem: { type: "string?" },
    },
    userFacingKey: null,
    functions: {
        total: {
            fn: calcContingencyItemTotal,
            parameterTypes: () => [CONTINGENCY_ITEM_META],
            returnType: { type: "money" },
        },
        certifiedForemanTotal: {
            fn: calcContingencyItemCertifiedForemanTotal,
            parameterTypes: () => [CONTINGENCY_ITEM_META],
            returnType: { type: "money" },
        },
    },
    segments: {},
};

// END MAGIC -- DO NOT EDIT
