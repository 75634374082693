import { Decimal } from "decimal.js";
import { Money } from "../../../clay/common";
import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";

//!Data
export type BillingCode = {
    id: UUID;
    recordVersion: Version;
    code: string;
    active: boolean;
    description: string;
    rate: Money;
};

//!Data
export type ThirdPartySpecifier = {
    id: UUID;
    recordVersion: Version;
    name: string;
    active: boolean;
};

//!Data
export type Competitor = {
    id: UUID;
    recordVersion: Version;
    name: string;
    active: boolean;
};

//!Data
export type Manufacturer = {
    id: UUID;
    recordVersion: Version;
    name: string;
    active: boolean;
};

//!Data
export type ApprovalType = {
    id: UUID;
    recordVersion: Version;
    name: string;
    active: boolean;
    requireDetail: boolean;
    requireCustomerPO: boolean;
};

//!Data
export type AnticipatedDuration = {
    id: UUID;
    recordVersion: Version;
    name: string;
    active: boolean;
};

//!Data
export type AvailableWorkingDays = {
    id: UUID;
    recordVersion: Version;
    name: string;
    active: boolean;
};

//!Data
export type AnticipatedCrewSize = {
    id: UUID;
    recordVersion: Version;
    name: string;
    active: boolean;
};

//!Data
export type RequiredEquipmentType = {
    id: UUID;
    recordVersion: Version;
    name: string;
    sizes: string[];
    showNumberOfDrops: boolean;
    showNumberOfStages: boolean;
    showBuildingHeight: boolean;
};

//!Data
export type RequiredNoticeType = {
    id: UUID;
    recordVersion: Version;
    name: string;
    active: boolean;
};

//!Data
export type ParkingType = {
    id: UUID;
    recordVersion: Version;
    name: string;
    active: boolean;
};

// BEGIN MAGIC -- DO NOT EDIT
export type BillingCodeJSON = {
    id: string;
    recordVersion: number | null;
    code: string;
    active: boolean;
    description: string;
    rate: string;
};

export function JSONToBillingCode(json: BillingCodeJSON): BillingCode {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        code: json.code,
        active: json.active,
        description: json.description,
        rate: new Decimal(json.rate),
    };
}
export type BillingCodeBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    code?: string;
    active?: boolean;
    description?: string;
    rate?: string;
};

export function newBillingCode(): BillingCode {
    return JSONToBillingCode(repairBillingCodeJSON(undefined));
}
export function repairBillingCodeJSON(
    json: BillingCodeBrokenJSON | undefined
): BillingCodeJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            code: json.code || "",
            active: json.active || false,
            description: json.description || "",
            rate: json.rate || "0",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            code: undefined || "",
            active: undefined || false,
            description: undefined || "",
            rate: undefined || "0",
        };
    }
}

export function BillingCodeToJSON(value: BillingCode): BillingCodeJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        code: value.code,
        active: value.active,
        description: value.description,
        rate: value.rate.toString(),
    };
}

export const BILLING_CODE_META: RecordMeta<
    BillingCode,
    BillingCodeJSON,
    BillingCodeBrokenJSON
> & { name: "BillingCode" } = {
    name: "BillingCode",
    type: "record",
    repair: repairBillingCodeJSON,
    toJSON: BillingCodeToJSON,
    fromJSON: JSONToBillingCode,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        code: { type: "string" },
        active: { type: "boolean" },
        description: { type: "string" },
        rate: { type: "money" },
    },
    userFacingKey: null,
    functions: {},
    segments: {},
};

export type ThirdPartySpecifierJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    active: boolean;
};

export function JSONToThirdPartySpecifier(
    json: ThirdPartySpecifierJSON
): ThirdPartySpecifier {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        active: json.active,
    };
}
export type ThirdPartySpecifierBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    active?: boolean;
};

export function newThirdPartySpecifier(): ThirdPartySpecifier {
    return JSONToThirdPartySpecifier(repairThirdPartySpecifierJSON(undefined));
}
export function repairThirdPartySpecifierJSON(
    json: ThirdPartySpecifierBrokenJSON | undefined
): ThirdPartySpecifierJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            active: json.active || false,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            active: undefined || false,
        };
    }
}

export function ThirdPartySpecifierToJSON(
    value: ThirdPartySpecifier
): ThirdPartySpecifierJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        active: value.active,
    };
}

export const THIRD_PARTY_SPECIFIER_META: RecordMeta<
    ThirdPartySpecifier,
    ThirdPartySpecifierJSON,
    ThirdPartySpecifierBrokenJSON
> & { name: "ThirdPartySpecifier" } = {
    name: "ThirdPartySpecifier",
    type: "record",
    repair: repairThirdPartySpecifierJSON,
    toJSON: ThirdPartySpecifierToJSON,
    fromJSON: JSONToThirdPartySpecifier,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        active: { type: "boolean" },
    },
    userFacingKey: "name",
    functions: {},
    segments: {},
};

export type CompetitorJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    active: boolean;
};

export function JSONToCompetitor(json: CompetitorJSON): Competitor {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        active: json.active,
    };
}
export type CompetitorBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    active?: boolean;
};

export function newCompetitor(): Competitor {
    return JSONToCompetitor(repairCompetitorJSON(undefined));
}
export function repairCompetitorJSON(
    json: CompetitorBrokenJSON | undefined
): CompetitorJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            active: json.active || false,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            active: undefined || false,
        };
    }
}

export function CompetitorToJSON(value: Competitor): CompetitorJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        active: value.active,
    };
}

export const COMPETITOR_META: RecordMeta<
    Competitor,
    CompetitorJSON,
    CompetitorBrokenJSON
> & { name: "Competitor" } = {
    name: "Competitor",
    type: "record",
    repair: repairCompetitorJSON,
    toJSON: CompetitorToJSON,
    fromJSON: JSONToCompetitor,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        active: { type: "boolean" },
    },
    userFacingKey: "name",
    functions: {},
    segments: {},
};

export type ManufacturerJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    active: boolean;
};

export function JSONToManufacturer(json: ManufacturerJSON): Manufacturer {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        active: json.active,
    };
}
export type ManufacturerBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    active?: boolean;
};

export function newManufacturer(): Manufacturer {
    return JSONToManufacturer(repairManufacturerJSON(undefined));
}
export function repairManufacturerJSON(
    json: ManufacturerBrokenJSON | undefined
): ManufacturerJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            active: json.active || false,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            active: undefined || false,
        };
    }
}

export function ManufacturerToJSON(value: Manufacturer): ManufacturerJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        active: value.active,
    };
}

export const MANUFACTURER_META: RecordMeta<
    Manufacturer,
    ManufacturerJSON,
    ManufacturerBrokenJSON
> & { name: "Manufacturer" } = {
    name: "Manufacturer",
    type: "record",
    repair: repairManufacturerJSON,
    toJSON: ManufacturerToJSON,
    fromJSON: JSONToManufacturer,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        active: { type: "boolean" },
    },
    userFacingKey: "name",
    functions: {},
    segments: {},
};

export type ApprovalTypeJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    active: boolean;
    requireDetail: boolean;
    requireCustomerPO: boolean;
};

export function JSONToApprovalType(json: ApprovalTypeJSON): ApprovalType {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        active: json.active,
        requireDetail: json.requireDetail,
        requireCustomerPO: json.requireCustomerPO,
    };
}
export type ApprovalTypeBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    active?: boolean;
    requireDetail?: boolean;
    requireCustomerPO?: boolean;
};

export function newApprovalType(): ApprovalType {
    return JSONToApprovalType(repairApprovalTypeJSON(undefined));
}
export function repairApprovalTypeJSON(
    json: ApprovalTypeBrokenJSON | undefined
): ApprovalTypeJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            active: json.active || false,
            requireDetail: json.requireDetail || false,
            requireCustomerPO: json.requireCustomerPO || false,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            active: undefined || false,
            requireDetail: undefined || false,
            requireCustomerPO: undefined || false,
        };
    }
}

export function ApprovalTypeToJSON(value: ApprovalType): ApprovalTypeJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        active: value.active,
        requireDetail: value.requireDetail,
        requireCustomerPO: value.requireCustomerPO,
    };
}

export const APPROVAL_TYPE_META: RecordMeta<
    ApprovalType,
    ApprovalTypeJSON,
    ApprovalTypeBrokenJSON
> & { name: "ApprovalType" } = {
    name: "ApprovalType",
    type: "record",
    repair: repairApprovalTypeJSON,
    toJSON: ApprovalTypeToJSON,
    fromJSON: JSONToApprovalType,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        active: { type: "boolean" },
        requireDetail: { type: "boolean" },
        requireCustomerPO: { type: "boolean" },
    },
    userFacingKey: "name",
    functions: {},
    segments: {},
};

export type AnticipatedDurationJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    active: boolean;
};

export function JSONToAnticipatedDuration(
    json: AnticipatedDurationJSON
): AnticipatedDuration {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        active: json.active,
    };
}
export type AnticipatedDurationBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    active?: boolean;
};

export function newAnticipatedDuration(): AnticipatedDuration {
    return JSONToAnticipatedDuration(repairAnticipatedDurationJSON(undefined));
}
export function repairAnticipatedDurationJSON(
    json: AnticipatedDurationBrokenJSON | undefined
): AnticipatedDurationJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            active: json.active || false,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            active: undefined || false,
        };
    }
}

export function AnticipatedDurationToJSON(
    value: AnticipatedDuration
): AnticipatedDurationJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        active: value.active,
    };
}

export const ANTICIPATED_DURATION_META: RecordMeta<
    AnticipatedDuration,
    AnticipatedDurationJSON,
    AnticipatedDurationBrokenJSON
> & { name: "AnticipatedDuration" } = {
    name: "AnticipatedDuration",
    type: "record",
    repair: repairAnticipatedDurationJSON,
    toJSON: AnticipatedDurationToJSON,
    fromJSON: JSONToAnticipatedDuration,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        active: { type: "boolean" },
    },
    userFacingKey: "name",
    functions: {},
    segments: {},
};

export type AvailableWorkingDaysJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    active: boolean;
};

export function JSONToAvailableWorkingDays(
    json: AvailableWorkingDaysJSON
): AvailableWorkingDays {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        active: json.active,
    };
}
export type AvailableWorkingDaysBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    active?: boolean;
};

export function newAvailableWorkingDays(): AvailableWorkingDays {
    return JSONToAvailableWorkingDays(
        repairAvailableWorkingDaysJSON(undefined)
    );
}
export function repairAvailableWorkingDaysJSON(
    json: AvailableWorkingDaysBrokenJSON | undefined
): AvailableWorkingDaysJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            active: json.active || false,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            active: undefined || false,
        };
    }
}

export function AvailableWorkingDaysToJSON(
    value: AvailableWorkingDays
): AvailableWorkingDaysJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        active: value.active,
    };
}

export const AVAILABLE_WORKING_DAYS_META: RecordMeta<
    AvailableWorkingDays,
    AvailableWorkingDaysJSON,
    AvailableWorkingDaysBrokenJSON
> & { name: "AvailableWorkingDays" } = {
    name: "AvailableWorkingDays",
    type: "record",
    repair: repairAvailableWorkingDaysJSON,
    toJSON: AvailableWorkingDaysToJSON,
    fromJSON: JSONToAvailableWorkingDays,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        active: { type: "boolean" },
    },
    userFacingKey: "name",
    functions: {},
    segments: {},
};

export type AnticipatedCrewSizeJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    active: boolean;
};

export function JSONToAnticipatedCrewSize(
    json: AnticipatedCrewSizeJSON
): AnticipatedCrewSize {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        active: json.active,
    };
}
export type AnticipatedCrewSizeBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    active?: boolean;
};

export function newAnticipatedCrewSize(): AnticipatedCrewSize {
    return JSONToAnticipatedCrewSize(repairAnticipatedCrewSizeJSON(undefined));
}
export function repairAnticipatedCrewSizeJSON(
    json: AnticipatedCrewSizeBrokenJSON | undefined
): AnticipatedCrewSizeJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            active: json.active || false,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            active: undefined || false,
        };
    }
}

export function AnticipatedCrewSizeToJSON(
    value: AnticipatedCrewSize
): AnticipatedCrewSizeJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        active: value.active,
    };
}

export const ANTICIPATED_CREW_SIZE_META: RecordMeta<
    AnticipatedCrewSize,
    AnticipatedCrewSizeJSON,
    AnticipatedCrewSizeBrokenJSON
> & { name: "AnticipatedCrewSize" } = {
    name: "AnticipatedCrewSize",
    type: "record",
    repair: repairAnticipatedCrewSizeJSON,
    toJSON: AnticipatedCrewSizeToJSON,
    fromJSON: JSONToAnticipatedCrewSize,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        active: { type: "boolean" },
    },
    userFacingKey: "name",
    functions: {},
    segments: {},
};

export type RequiredEquipmentTypeJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    sizes: string[];
    showNumberOfDrops: boolean;
    showNumberOfStages: boolean;
    showBuildingHeight: boolean;
};

export function JSONToRequiredEquipmentType(
    json: RequiredEquipmentTypeJSON
): RequiredEquipmentType {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        sizes: json.sizes.map((inner) => inner),
        showNumberOfDrops: json.showNumberOfDrops,
        showNumberOfStages: json.showNumberOfStages,
        showBuildingHeight: json.showBuildingHeight,
    };
}
export type RequiredEquipmentTypeBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    sizes?: string[];
    showNumberOfDrops?: boolean;
    showNumberOfStages?: boolean;
    showBuildingHeight?: boolean;
};

export function newRequiredEquipmentType(): RequiredEquipmentType {
    return JSONToRequiredEquipmentType(
        repairRequiredEquipmentTypeJSON(undefined)
    );
}
export function repairRequiredEquipmentTypeJSON(
    json: RequiredEquipmentTypeBrokenJSON | undefined
): RequiredEquipmentTypeJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            sizes: (json.sizes || []).map((inner) => inner || ""),
            showNumberOfDrops: json.showNumberOfDrops || false,
            showNumberOfStages: json.showNumberOfStages || false,
            showBuildingHeight: json.showBuildingHeight || false,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            sizes: (undefined || []).map((inner) => inner || ""),
            showNumberOfDrops: undefined || false,
            showNumberOfStages: undefined || false,
            showBuildingHeight: undefined || false,
        };
    }
}

export function RequiredEquipmentTypeToJSON(
    value: RequiredEquipmentType
): RequiredEquipmentTypeJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        sizes: value.sizes.map((inner) => inner),
        showNumberOfDrops: value.showNumberOfDrops,
        showNumberOfStages: value.showNumberOfStages,
        showBuildingHeight: value.showBuildingHeight,
    };
}

export const REQUIRED_EQUIPMENT_TYPE_META: RecordMeta<
    RequiredEquipmentType,
    RequiredEquipmentTypeJSON,
    RequiredEquipmentTypeBrokenJSON
> & { name: "RequiredEquipmentType" } = {
    name: "RequiredEquipmentType",
    type: "record",
    repair: repairRequiredEquipmentTypeJSON,
    toJSON: RequiredEquipmentTypeToJSON,
    fromJSON: JSONToRequiredEquipmentType,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        sizes: { type: "array", items: { type: "string" } },
        showNumberOfDrops: { type: "boolean" },
        showNumberOfStages: { type: "boolean" },
        showBuildingHeight: { type: "boolean" },
    },
    userFacingKey: "name",
    functions: {},
    segments: {},
};

export type RequiredNoticeTypeJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    active: boolean;
};

export function JSONToRequiredNoticeType(
    json: RequiredNoticeTypeJSON
): RequiredNoticeType {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        active: json.active,
    };
}
export type RequiredNoticeTypeBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    active?: boolean;
};

export function newRequiredNoticeType(): RequiredNoticeType {
    return JSONToRequiredNoticeType(repairRequiredNoticeTypeJSON(undefined));
}
export function repairRequiredNoticeTypeJSON(
    json: RequiredNoticeTypeBrokenJSON | undefined
): RequiredNoticeTypeJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            active: json.active || false,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            active: undefined || false,
        };
    }
}

export function RequiredNoticeTypeToJSON(
    value: RequiredNoticeType
): RequiredNoticeTypeJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        active: value.active,
    };
}

export const REQUIRED_NOTICE_TYPE_META: RecordMeta<
    RequiredNoticeType,
    RequiredNoticeTypeJSON,
    RequiredNoticeTypeBrokenJSON
> & { name: "RequiredNoticeType" } = {
    name: "RequiredNoticeType",
    type: "record",
    repair: repairRequiredNoticeTypeJSON,
    toJSON: RequiredNoticeTypeToJSON,
    fromJSON: JSONToRequiredNoticeType,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        active: { type: "boolean" },
    },
    userFacingKey: "name",
    functions: {},
    segments: {},
};

export type ParkingTypeJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    active: boolean;
};

export function JSONToParkingType(json: ParkingTypeJSON): ParkingType {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        active: json.active,
    };
}
export type ParkingTypeBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    active?: boolean;
};

export function newParkingType(): ParkingType {
    return JSONToParkingType(repairParkingTypeJSON(undefined));
}
export function repairParkingTypeJSON(
    json: ParkingTypeBrokenJSON | undefined
): ParkingTypeJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            active: json.active || false,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            active: undefined || false,
        };
    }
}

export function ParkingTypeToJSON(value: ParkingType): ParkingTypeJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        active: value.active,
    };
}

export const PARKING_TYPE_META: RecordMeta<
    ParkingType,
    ParkingTypeJSON,
    ParkingTypeBrokenJSON
> & { name: "ParkingType" } = {
    name: "ParkingType",
    type: "record",
    repair: repairParkingTypeJSON,
    toJSON: ParkingTypeToJSON,
    fromJSON: JSONToParkingType,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        active: { type: "boolean" },
    },
    userFacingKey: "name",
    functions: {},
    segments: {},
};

// END MAGIC -- DO NOT EDIT
