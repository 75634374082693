import { AdminCollectionPage } from "../../../clay/admin-collection-page";
import { AdminTablePage } from "../../../clay/admin-table";
import AnticipatedCrewSizeWidget from "./AnticipatedCrewSizeWidget.widget";
import AnticipatedDurationWidget from "./AnticipatedDurationWidget.widget";
import ApprovalTypeWidget from "./ApprovalTypeWidget.widget";
import AvailableWorkingDaysWidget from "./AvailableWorkingDaysWidget.widget";
import BillingCodeWidget from "./BillingCode.widget";
import CompetitorWidget from "./CompetitorWidget.widget";
import ManufacturerWidget from "./ManufacturerWidget.widget";
import ParkingTypeWidget from "./ParkingType.widget";
import RequiredEquipmentTypeWidget from "./RequiredEquipmentTypeWidget.widget";
import RequiredNoticeTypeWidget from "./RequiredNoticeType.widget";
import { AnticipatedDuration } from "./table";
import ThirdPartySpecifierWidget from "./ThirdPartySpecifierWidget.widget";
import * as React from "react";

export const ThirdPartySpecifiersPage = AdminTablePage({
    adminCategory: "projects",
    rowWidget: ThirdPartySpecifierWidget,
    compare: (lhs, rhs) => lhs.name.localeCompare(rhs.name),
    columns: [
        {
            id: "name",
            label: "Name",
            width: 400,
        },
        {
            id: "active",
            label: "Active",
        },
    ],
});

export const BillingCodesPage = AdminTablePage({
    adminCategory: "projects",
    rowWidget: BillingCodeWidget,
    compare: (lhs, rhs) => lhs.code.localeCompare(rhs.code),
    columns: [
        {
            id: "code",
            label: "Code",
            width: 400,
        },
        {
            id: "active",
            label: "Active",
        },
        {
            id: "description",
            label: "Description",
        },
        {
            id: "rate",
            label: "Base Rate",
        },
    ],
});

export const CompetitorsPage = AdminTablePage({
    adminCategory: "projects",
    rowWidget: CompetitorWidget,
    compare: (lhs, rhs) => lhs.name.localeCompare(rhs.name),
    columns: [
        {
            id: "name",
            label: "Name",
            width: 400,
        },
        {
            id: "active",
            label: "Active",
        },
    ],
});

export const ManufacturersPage = AdminTablePage({
    adminCategory: "projects",
    rowWidget: ManufacturerWidget,
    compare: (lhs, rhs) => lhs.name.localeCompare(rhs.name),
    columns: [
        {
            id: "name",
            label: "Name",
            width: 400,
        },
        {
            id: "active",
            label: "Active",
        },
    ],
});

function classifyUnit(name: string): number {
    if (name.startsWith(">")) {
        return 3;
    } else if (name.toLowerCase().indexOf("days") !== -1) {
        return 1;
    } else if (name.toLowerCase().indexOf("weeks") !== -1) {
        return 2;
    } else {
        return 0;
    }
}

export function durationCompare(
    lhs: AnticipatedDuration,
    rhs: AnticipatedDuration
) {
    const lhsUnit = classifyUnit(lhs.name);
    const rhsUnit = classifyUnit(rhs.name);
    if (lhsUnit != rhsUnit) {
        return lhsUnit - rhsUnit;
    } else {
        return parseInt(lhs.name) - parseInt(rhs.name);
    }
}

export const AnticipatedDurationsPage = AdminTablePage({
    adminCategory: "projects",
    rowWidget: AnticipatedDurationWidget,
    compare: durationCompare,
    columns: [
        {
            id: "name",
            label: "Name",
            width: 400,
        },
        {
            id: "active",
            label: "Active",
        },
    ],
});

export const ApprovalTypesPage = AdminTablePage({
    adminCategory: "projects",
    rowWidget: ApprovalTypeWidget,
    compare: (lhs, rhs) => lhs.name.localeCompare(rhs.name),
    columns: [
        {
            id: "name",
            label: "Name",
            width: 400,
        },
        {
            id: "active",
            label: "Active",
        },
        {
            id: "requireCustomerPO",
            label: "Require Customer PO",
        },
    ],
});

export const RequiredEquipmentTypePage = AdminCollectionPage({
    meta: RequiredEquipmentTypeWidget,
    labelColumn: "name",
    urlPrefix: "#/admin/required-equipment-types",
    adminCategory: "project",
});

export const AvailableWorkingDaysPage = AdminTablePage({
    adminCategory: "projects",
    rowWidget: AvailableWorkingDaysWidget,
    compare: (lhs, rhs) => lhs.name.localeCompare(rhs.name),
    columns: [
        {
            id: "name",
            label: "Name",
            width: 400,
        },
        {
            id: "active",
            label: "Active",
        },
    ],
});

export const AnticipatedCrewSizePage = AdminTablePage({
    adminCategory: "projects",
    rowWidget: AnticipatedCrewSizeWidget,
    compare: (lhs, rhs) => lhs.name.localeCompare(rhs.name),
    columns: [
        {
            id: "name",
            label: "Name",
            width: 400,
        },
        {
            id: "active",
            label: "Active",
        },
    ],
});

export const RequiredNoticeTypePage = AdminTablePage({
    adminCategory: "projects",
    rowWidget: RequiredNoticeTypeWidget,
    compare: (lhs, rhs) => lhs.name.localeCompare(rhs.name),
    columns: [
        {
            id: "name",
            label: "Name",
            width: 400,
        },
        {
            id: "active",
            label: "Active",
        },
    ],
});

export const ParkingTypePage = AdminTablePage({
    adminCategory: "projects",
    rowWidget: ParkingTypeWidget,
    compare: (lhs, rhs) => lhs.name.localeCompare(rhs.name),
    columns: [
        {
            id: "name",
            label: "Name",
            width: 400,
        },
        {
            id: "active",
            label: "Active",
        },
    ],
});
