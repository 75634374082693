import { PageContext } from "../../../clay/Page";
import { PaginatedWidget } from "../../../clay/paginated-widget";
import ScheduleOfValuesExportWidget from "../overview/ScheduleOfValuesExportWidget.widget";
import SpectrumExportWidget from "../overview/SpectrumExportWidget.widget";
import ProjectAccountingWidget from "../ProjectAccountingWidget.widget";
import { Project, PROJECT_META } from "../table";
import PhaseCodes from "./PhaseCodes.widget";
import * as React from "react";

export const AccountingTabsWidget = PaginatedWidget<Project, PageContext>({
    dataMeta: PROJECT_META,
    pages() {
        return [
            {
                id: "accounting",
                title: "Project Details",
                widget: ProjectAccountingWidget,
            },
            {
                id: "phasecode",
                title: "Phase Code",
                widget: PhaseCodes,
            },
            {
                id: "spectrum",
                title: "Phase Code Export",
                widget: SpectrumExportWidget,
            },
            {
                id: "schedule-of-value",
                title: "Schedule of Values Export",
                widget: ScheduleOfValuesExportWidget,
            },
        ];
    },
});
