import { every, some } from "lodash";
import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { FormField } from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { ListWidget } from "../../../clay/widgets/ListWidget";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import ContingencyItemExWidget from "../../contingency/ContingencyItemExWidget.widget";
import {
    calcContingencyItemTotal,
    computeContingencyItemRemdalCost,
} from "../../contingency/table";
import { CONTENT_AREA, TABLE_STYLE } from "../../styles";
import {
    DetailSheet,
    DETAIL_SHEET_META,
    resolveDetailSheetSchedules,
} from "./table";
import { TotalsSection } from "./TotalsSection";

export type Data = DetailSheet;

export const Fields = {
    contingencyItems: ListWidget(ContingencyItemExWidget, { emptyOk: true }),
    schedulesDividedDescription: FormField(SwitchWidget),
};

function validate(data: Data, cache: QuickCacheApi) {
    let errors = baseValidate(data, cache);

    if (data.change) {
        errors = errors.filter((error) => error.field !== "projectedStartDate");
    }

    if (data.schedulesDividedDescription) {
        errors = errors.filter((error) => error.field !== "description");
    } else {
        errors = errors.filter(
            (error) =>
                error.invalid ||
                (error.field !== "schedules" &&
                    error.field !== "contingencyItems") ||
                every(error.detail!, (detail) =>
                    some(
                        detail.detail!,
                        (detail) => detail.field !== "projectDescription"
                    )
                )
        );
    }

    return errors;
}

function reduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    const inner = baseReduce(state, data, action, context);
    return {
        state: inner.state,
        data: resolveDetailSheetSchedules(inner.data),
    };
}

function actionFinalize(state: State, data: Data) {
    return {
        state,
        data: {
            ...data,
            date: data.date || new Date(),
        },
    };
}

function Component(props: Props) {
    return (
        <>
            <div {...CONTENT_AREA}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        marginTop: "2em",
                        marginBottom: "2em",
                    }}
                >
                    <table
                        {...TABLE_STYLE}
                        style={{
                            width: "100%",
                            marginRight: "auto",
                        }}
                    >
                        <thead>
                            <tr>
                                <th style={{ width: "2em" }} />
                                <th>Description</th>
                                <th>Master Format Code</th>
                                <th style={{ width: "0em" }}>Non-CF Expense</th>
                                <th style={{ width: "10em" }}>
                                    Project Quantity
                                </th>
                                <th style={{ width: "8em" }}>Unit Type</th>
                                <th style={{ width: "10em" }}>Unit Rate</th>
                                <th style={{ width: "10em" }}>CF Unit Rate</th>
                                <th style={{ width: "10em" }}>
                                    Remdal Contract Allowance
                                </th>
                                <th style={{ width: "10em" }}>
                                    Anticipated Remdal Expense
                                </th>
                                <th style={{ width: "1em" }} />
                            </tr>
                        </thead>
                        <widgets.contingencyItems
                            containerClass="tbody"
                            extraItemForAdd
                            itemProps={{
                                ex: true,
                                dividedDescription:
                                    props.data.schedulesDividedDescription,
                            }}
                        />
                        <TotalsSection
                            items={props.data.contingencyItems}
                            columns={[
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    value: calcContingencyItemTotal,
                                    money: true,
                                },
                                {
                                    value: computeContingencyItemRemdalCost,
                                    money: true,
                                },
                            ]}
                        />
                    </table>
                </div>
            </div>
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.contingencyItems> &
    WidgetContext<typeof Fields.schedulesDividedDescription>;
type ExtraProps = {};
type BaseState = {
    contingencyItems: WidgetState<typeof Fields.contingencyItems>;
    schedulesDividedDescription: WidgetState<
        typeof Fields.schedulesDividedDescription
    >;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | {
          type: "CONTINGENCY_ITEMS";
          action: WidgetAction<typeof Fields.contingencyItems>;
      }
    | {
          type: "SCHEDULES_DIVIDED_DESCRIPTION";
          action: WidgetAction<typeof Fields.schedulesDividedDescription>;
      }
    | { type: "FINALIZE" };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        Fields.contingencyItems,
        data.contingencyItems,
        cache,
        "contingencyItems",
        errors
    );
    subvalidate(
        Fields.schedulesDividedDescription,
        data.schedulesDividedDescription,
        cache,
        "schedulesDividedDescription",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "CONTINGENCY_ITEMS": {
            const inner = Fields.contingencyItems.reduce(
                state.contingencyItems,
                data.contingencyItems,
                action.action,
                subcontext
            );
            return {
                state: { ...state, contingencyItems: inner.state },
                data: { ...data, contingencyItems: inner.data },
            };
        }
        case "SCHEDULES_DIVIDED_DESCRIPTION": {
            const inner = Fields.schedulesDividedDescription.reduce(
                state.schedulesDividedDescription,
                data.schedulesDividedDescription,
                action.action,
                subcontext
            );
            return {
                state: { ...state, schedulesDividedDescription: inner.state },
                data: { ...data, schedulesDividedDescription: inner.data },
            };
        }
        case "FINALIZE":
            return actionFinalize(state, data);
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    contingencyItems: function (
        props: WidgetExtraProps<typeof Fields.contingencyItems> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "CONTINGENCY_ITEMS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "contingencyItems", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.contingencyItems.component
                state={context.state.contingencyItems}
                data={context.data.contingencyItems}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Contingency Items"}
            />
        );
    },
    schedulesDividedDescription: function (
        props: WidgetExtraProps<typeof Fields.schedulesDividedDescription> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "SCHEDULES_DIVIDED_DESCRIPTION",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "schedulesDividedDescription",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.schedulesDividedDescription.component
                state={context.state.schedulesDividedDescription}
                data={context.data.schedulesDividedDescription}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Schedules Divided Description"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: DETAIL_SHEET_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let contingencyItemsState;
        {
            const inner = Fields.contingencyItems.initialize(
                data.contingencyItems,
                subcontext,
                subparameters.contingencyItems
            );
            contingencyItemsState = inner.state;
            data = { ...data, contingencyItems: inner.data };
        }
        let schedulesDividedDescriptionState;
        {
            const inner = Fields.schedulesDividedDescription.initialize(
                data.schedulesDividedDescription,
                subcontext,
                subparameters.schedulesDividedDescription
            );
            schedulesDividedDescriptionState = inner.state;
            data = { ...data, schedulesDividedDescription: inner.data };
        }
        let state = {
            initialParameters: parameters,
            contingencyItems: contingencyItemsState,
            schedulesDividedDescription: schedulesDividedDescriptionState,
        };
        return {
            state,
            data,
        };
    },
    validate: validate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={DETAIL_SHEET_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: reduce,
};
export default Widget;
type Widgets = {
    contingencyItems: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.contingencyItems>
    >;
    schedulesDividedDescription: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.schedulesDividedDescription>
    >;
};
// END MAGIC -- DO NOT EDIT
